import { Word } from "../grammar/Word";
import {
  adjective,
  adjectiveWithoutComparativeAndSuperlative,
  nounCommon,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryL: Word[] = [
  adjectiveWithoutComparativeAndSuperlative(
    { dk: "levende", en: "alive / living" },
    { dk: "levende", en: "alive / living" },
    { dk: "levende", en: "alive / living" },
    2,
    [Keyword.ANIMALS, Keyword.PEOPLE]
  ),
  verb(
    { dk: "kunne lide", en: "like" },
    { dk: "kan lide", en: "like" },
    { dk: "kunne lide", en: "liked" },
    2,
    [Keyword.CONVERSATIONS, Keyword.RELATIONSHIPS]
  ),
  adjectiveWithoutComparativeAndSuperlative(
    { dk: "lovlig", en: "legal" },
    { dk: "lovligt", en: "legal" },
    { dk: "lovlige", en: "legal" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  adjective(
    { dk: "lys", en: "light / bright" },
    { dk: "lyst", en: "light / bright" },
    {
      dk: "lyse",
      en: "light / bright",
    },
    {
      dk: "lysere",
      en: "lighter / brighter",
    },
    { dk: "lysest", en: "lightest / brightest" },
    2,
    [Keyword.COLORS]
  ),
  verb(
    { dk: "lytte", en: "listen" },
    { dk: "lytter", en: "listen" },
    { dk: "lyttede", en: "listened" },
    2,
    [Keyword.CONVERSATIONS, Keyword.EDUCATION, Keyword.LEISURE]
  ),
  verb(
    { dk: "lære", en: "learn/teach" },
    { dk: "lærer", en: "learn/teach" },
    { dk: "lærte", en: "learnt/taught" },
    2,
    [Keyword.EDUCATION]
  ),
  nounCommon(
    { dk: "lørdag", en: "Saturday" },
    { dk: "lørdagen", en: "Saturday" },
    {
      dk: "lørdage",
      en: "Saturdays",
    },
    { dk: "lørdagene", en: "Saturdays" },
    2,
    [Keyword.TIME]
  ),
  nounCommon(
    { dk: "løve", en: "lion" },
    { dk: "løven", en: "lion" },
    {
      dk: "løver",
      en: "lions",
    },
    { dk: "løverne", en: "lions" },
    1,
    [Keyword.ANIMALS]
  ),
];