import { Word } from "../grammar/Word";
import { adjective, verb } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryR: Word[] = [
  adjective(
    { dk: "ren", en: "clean" },
    { dk: "rent", en: "clean" },
    { dk: "rene", en: "clean" },
    { dk: "renere", en: "cleaner" },
    { dk: "renest / reneste", en: "cleanest" },
    2,
    [Keyword.CONVERSATIONS, Keyword.PLACES]
  ),
  adjective(
    { dk: "rød", en: "red" },
    { dk: "rødt", en: "red" },
    {
      dk: "røde",
      en: "red",
    },
    {
      dk: "rødere",
      en: "redder",
    },
    { dk: "rødest / rødeste", en: "reddest" },
    2,
    [Keyword.COLORS]
  ),
  verb(
    { dk: "røre", en: "touch" },
    { dk: "rører", en: "touch" },
    {
      dk: "rørte",
      en: "touched",
    },
    2,
    [Keyword.CONVERSATIONS]
  ),
];