import { FormEvent, useState } from "react";
import CourseUnit from "../../course/CourseUnit";
import { toggleAndReturnCopy } from "../../utils/CollectionUtils";
import { KeywordData } from "../../course/Keyword";

export function useAddEntryController() {
  const [word, setWord] = useState("");
  const [wordErrorMessage, setWordErrorMessage] = useState("");
  const [unit, setUnit] = useState(`${CourseUnit.first}`);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const add = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (word === "") {
      setWordErrorMessage("Word is required");
      return;
    }

    sendEntryRequest(word, unit, keywords).then(() => {
      setWord("");
      setKeywords([]);
      setSnackbarOpen(true);
    });
  };

  const updateWord = (value: string) => {
    setWordErrorMessage("");
    setWord(value);
  };

  const toggleKeyword = (id: string, checked: boolean) => {
    const newKeywords = toggleAndReturnCopy(keywords, id, checked);
    if (newKeywords !== undefined) {
      setKeywords(() => newKeywords);
    }
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return {
    add: add,
    word: word,
    setWord: updateWord,
    wordErrorMessage: wordErrorMessage,
    unit: unit,
    setUnit: setUnit,
    keywords: keywords,
    toggleKeyword: toggleKeyword,
    snackbarOpen: snackbarOpen,
    closeSnackbar: closeSnackbar,
  };
}

async function sendEntryRequest(
  word: string,
  unit: string,
  keywords: string[]
) {
  const keywordNames = keywords
    .map((keyword) => KeywordData[parseInt(keyword)].name)
    .join(", ");
  const message = `Word:\t\t${word}\nUnit:\t\t${unit}\nKeywords:\t${keywordNames}`;
  const body = {
    subject: `Danish course notes entry suggestion: ${word}`,
    message: message,
  };
  const options = {
    method: "POST",
    body: JSON.stringify(body),
  };

  await fetch("https://kwezal.com/mail/", options);
}