import { Word } from "../grammar/Word";
import {
  numberSeven,
  numberSeventeen,
  numberSix,
  numberSixteen,
  numberTwentySeven,
  numberTwentySix,
} from "./Numbers";
import {
  adjective,
  adverb,
  nounCommon,
  nounCommonSingularIndefinite,
  nounNeuter,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryS: Word[] = [
  numberSix,
  numberTwentySix,
  numberSixteen,
  nounNeuter(
    { dk: "sekund", en: "second" },
    { dk: "sekundet", en: "second" },
    {
      dk: "sekunder",
      en: "seconds",
    },
    { dk: "sekunderne", en: "seconds" },
    2,
    [Keyword.TIME]
  ),
  adverb({ dk: "selvfølgelig", en: "of course" }, 2, [Keyword.CONVERSATIONS]),
  nounCommonSingularIndefinite({ dk: "september", en: "September" }, 2, [
    Keyword.TIME,
  ]),
  verb(
    { dk: "se", en: "see" },
    { dk: "ser", en: "see" },
    { dk: "så", en: "saw" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  nounCommon(
    { dk: "skildpadde", en: "turtle" },
    { dk: "skildpadden", en: "turtle" },
    {
      dk: "skildpadder",
      en: "turtles",
    },
    { dk: "skildpadderne", en: "turtles" },
    1,
    [Keyword.ANIMALS]
  ),
  nounCommon(
    { dk: "skjorte", en: "shirt" },
    { dk: "skjorten", en: "shirt" },
    {
      dk: "skjorter",
      en: "shirts",
    },
    { dk: "skjorterne", en: "shirts" },
    1,
    [Keyword.CLOTHES]
  ),
  nounCommon(
    { dk: "sko", en: "shoe" },
    { dk: "skoen", en: "shoe" },
    {
      dk: "sko",
      en: "shoes",
    },
    { dk: "skoene", en: "shoes" },
    1,
    [Keyword.CLOTHES]
  ),
  adjective(
    { dk: "sort", en: "black" },
    { dk: "sort", en: "black" },
    {
      dk: "sorte",
      en: "black",
    },
    {
      dk: "sortere",
      en: "blacker",
    },
    { dk: "sortest / sorteste", en: "blackest" },
    2,
    [Keyword.COLORS]
  ),
  verb(
    { dk: "sove", en: "sleep" },
    { dk: "sover", en: "sleep" },
    { dk: "sov", en: "slept" },
    2,
    [Keyword.LEISURE]
  ),
  verb(
    { dk: "spise", en: "eat" },
    { dk: "spiser", en: "eat" },
    { dk: "spiste", en: "ate" },
    1,
    [Keyword.FOOD]
  ),
  nounNeuter(
    { dk: "spørgsmål", en: "question" },
    { dk: "spørgsmålet", en: "question" },
    {
      dk: "spørgsmål",
      en: "questions",
    },
    { dk: "spørgsmålene", en: "questions" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  verb(
    { dk: "støtte", en: "support" },
    { dk: "støtter", en: "support" },
    {
      dk: "støttede",
      en: "supported",
    },
    2,
    [Keyword.CONVERSATIONS]
  ),
  nounCommon(
    { dk: "strømpe", en: "sock" },
    { dk: "strømpen", en: "sock" },
    {
      dk: "strømper",
      en: "socks",
    },
    { dk: "strømperne", en: "socks" },
    1,
    [Keyword.CLOTHES]
  ),
  nounNeuter(
    { dk: "svar", en: "answer" },
    { dk: "svaret", en: "answer" },
    {
      dk: "svar",
      en: "answers",
    },
    { dk: "svarene", en: "answers" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  verb(
    { dk: "svømmer", en: "swim" },
    { dk: "svømmer", en: "swim" },
    {
      dk: "svømmede",
      en: "swam",
    },
    2,
    [Keyword.LEISURE]
  ),
  verb(
    { dk: "synge", en: "sing" },
    { dk: "synger", en: "sing" },
    {
      dk: "sang",
      en: "sang",
    },
    2,
    [Keyword.LEISURE, Keyword.WORK]
  ),
  numberSeventeen,
  numberSeven,
  numberTwentySeven,
  nounCommon(
    { dk: "søskende", en: "sibling" },
    { dk: "søskende", en: "sibling" },
    {
      dk: "søskende",
      en: "siblings",
    },
    { dk: "søskende", en: "siblings" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "søndag", en: "Sunday" },
    { dk: "søndagen", en: "Sunday" },
    {
      dk: "søndage",
      en: "Sundays",
    },
    { dk: "søndagene", en: "Sundays" },
    2,
    [Keyword.TIME]
  ),
  nounCommon(
    { dk: "søster", en: "sister" },
    { dk: "søsteren", en: "sister" },
    {
      dk: "søstre",
      en: "sisters",
    },
    { dk: "søstrene", en: "sisters" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
];