import { Grid, List, ListItem } from "@mui/material";
import { numbers } from "../../dictionary/Numbers";
import NumeralListItem from "../grammar/NumeralListItem";
import { Numeral } from "../../grammar/Word";

interface Props {
  readonly columns: number;
}

export default function NumbersPage(props: Props) {
  const { columns } = props;
  const gridSize = Math.ceil(12 / columns);
  const numbersInColumns = putNumbersInColumns(numbers, columns);

  return (
    <List>
      <Grid container>
        {numbersInColumns.map((number, index) => (
          <Grid key={index} item xs={gridSize}>
            <ListItem divider>
              <NumeralListItem word={number} />
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </List>
  );
}

function putNumbersInColumns(numbers: Numeral[], columnNumber: number) {
  const fullColumnSize = Math.floor(numbers.length / columnNumber);
  const extendedColumns = numbers.length - fullColumnSize * columnNumber;

  const columns: Numeral[][] = Array(columnNumber).fill([]);
  let end = 0;
  for (let i = 0; i < columnNumber; ++i) {
    const column = i % columnNumber;
    const newEnd = end + fullColumnSize + (column < extendedColumns ? 1 : 0);
    columns[i] = numbers.slice(end, newEnd);
    end = newEnd;
  }

  const numbersInColumns: Numeral[] = [];

  let index = 0;
  for (let i = 0; i < numbers.length; ++i) {
    const column = i % columnNumber;
    numbersInColumns.push(columns[column][index]);
    if (column === columnNumber - 1) {
      ++index;
    }
  }
  return numbersInColumns;
}
