import { List } from "@mui/material";
import DictionaryEntryListItem from "./DictionaryEntryListItem";
import { dictionary } from "../../dictionary/Dictionary";
import { useLocation } from "react-router-dom";
import {
  Adjective,
  Adverb,
  Conjunction,
  Noun,
  Numeral,
  Preposition,
  Verb,
  Word,
} from "../../grammar/Word";
import { PartOfSpeech } from "../../grammar/PartOfSpeech";
import CourseUnit from "../../course/CourseUnit";
import { Keyword } from "../../course/Keyword";

export default function DictionaryEntryList() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const wordQuery = params.get("word") ?? "";
  const unitFrom = parseInt(params.get("unitFrom") ?? `${CourseUnit.first}`);
  const unitTo = parseInt(params.get("unitTo") ?? `${CourseUnit.last}`);
  const keywordsParam = params.get("keywords");
  const keywords =
    (keywordsParam ?? "") === ""
      ? []
      : keywordsParam!.split(",").map((keyword) => parseInt(keyword));

  const listItems = dictionary.map((word, index) =>
    filter(word, wordQuery, unitFrom, unitTo, keywords) ? (
      <DictionaryEntryListItem key={index} word={word} />
    ) : null
  );

  return <List>{listItems}</List>;
}

function filter(
  word: Word,
  wordQuery: string,
  unitFrom: number,
  unitTo: number,
  keywords: Keyword[]
) {
  if (word.unit < unitFrom || word.unit > unitTo) {
    return false;
  }

  if (
    keywords.length > 0 &&
    !word.keywords.some((keyword) => keywords.includes(keyword))
  ) {
    return false;
  }

  if (wordQuery !== "") {
    const normalizedWordQuery = normalizeString(wordQuery);
    switch (word.part) {
      case PartOfSpeech.ADJECTIVE: {
        const adjective = word as Adjective;
        if (
          !normalizeString(adjective.commonIndefinite?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.commonIndefinite?.en).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.neuterIndefinite?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.neuterIndefinite?.en).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.pluralAndDefinite?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.pluralAndDefinite?.en).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.comparative?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.comparative?.en).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.superlative?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(adjective.superlative?.en).includes(
            normalizedWordQuery
          )
        ) {
          return false;
        }
        break;
      }
      case PartOfSpeech.ADVERB: {
        const adverb = word as Adverb;
        if (
          !normalizeString(adverb.text.dk).includes(normalizedWordQuery) &&
          !normalizeString(adverb.text.en).includes(normalizedWordQuery)
        ) {
          return false;
        }
        break;
      }
      case PartOfSpeech.CONJUNCTION: {
        const conjunction = word as Conjunction;
        if (
          !normalizeString(conjunction.text.dk).includes(normalizedWordQuery) &&
          !normalizeString(conjunction.text.en).includes(normalizedWordQuery)
        ) {
          return false;
        }
        break;
      }
      case PartOfSpeech.NOUN: {
        const noun = word as Noun;
        if (
          !normalizeString(noun.singularIndefinite?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(noun.singularIndefinite?.en).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(noun.singularDefinite?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(noun.singularDefinite?.en).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(noun.pluralIndefinite?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(noun.pluralIndefinite?.en).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(noun.pluralIndefinite?.dk).includes(
            normalizedWordQuery
          ) &&
          !normalizeString(noun.pluralIndefinite?.en).includes(
            normalizedWordQuery
          )
        ) {
          return false;
        }
        break;
      }
      case PartOfSpeech.NUMERAL: {
        const conjunction = word as Numeral;
        if (
            !normalizeString(conjunction.text.dk).includes(normalizedWordQuery) &&
            !normalizeString(conjunction.text.en).includes(normalizedWordQuery)
        ) {
          return false;
        }
        break;
      }
      case PartOfSpeech.PREPOSITION: {
        const preposition = word as Preposition;
        if (
          !normalizeString(preposition.text.dk).includes(normalizedWordQuery) &&
          !normalizeString(preposition.text.en).includes(normalizedWordQuery)
        ) {
          return false;
        }
        break;
      }
      case PartOfSpeech.VERB: {
        const verb = word as Verb;
        if (
          !normalizeString(verb.infinitive.dk).includes(normalizedWordQuery) &&
          !normalizeString(verb.infinitive.en).includes(normalizedWordQuery) &&
          !normalizeString(verb.present.dk).includes(normalizedWordQuery) &&
          !normalizeString(verb.present.en).includes(normalizedWordQuery) &&
          !normalizeString(verb.past.dk).includes(normalizedWordQuery) &&
          !normalizeString(verb.past.en).includes(normalizedWordQuery)
        ) {
          return false;
        }
        break;
      }
    }
  }

  return true;
}

function normalizeString(s?: string) {
  if (s === undefined) {
    return "";
  }

  return s
    .toLowerCase()
    .replaceAll("å", "a")
    .replaceAll("æ", "ae")
    .replaceAll("ø", "o");
}
