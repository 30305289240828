export function toggleAndReturnCopy<T>(
  array: T[],
  element: T,
  present: boolean
): T[] | undefined {
  const index = array.indexOf(element);
  if (present) {
    if (index === -1) {
      return [...array, element];
    }
  } else {
    if (index !== -1) {
      const newKeywords = [...array];
      newKeywords.splice(index, 1);
      return newKeywords;
    }
  }
}
