import { Box } from "@mui/material";
import DictionaryPage from "../dictionary/DictionaryPage";
import MainPageAppBar from "./MainPageAppBar";
import { useRoutes } from "react-router-dom";
import AppRoutes from "../../route/AppRoutes";
import React from "react";
import AddEntryPage from "../add-entry/AddEntryPage";
import NumbersPage from "../numbers/NumbersPage";

function MainPageBody() {
  return useRoutes([
    { path: AppRoutes.home, element: <DictionaryPage /> },
    { path: AppRoutes.addEntry, element: <AddEntryPage /> },
    { path: AppRoutes.numbers, element: <NumbersPage columns={3} /> },
  ]);
}

export default function MainPage() {
  return (
    <>
      <MainPageAppBar />
      <Box mt={7}>
        <MainPageBody />
      </Box>
    </>
  );
}
