import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { Link } from "react-router-dom";
import AppRoutes from "../../route/AppRoutes";
import { useThemeContext } from "../../theme/ThemeContext";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import LightModeIcon from "@mui/icons-material/LightMode";
import { darkTheme } from "../../theme/Theme";

export default function MainPageAppBar() {
  const scrollTrigger = useScrollTrigger();
  const { theme, toggleTheme } = useThemeContext();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleToggleTheme = () => {
    toggleTheme();
  };

  const drawerOptions = [
    { text: "Dictionary", path: AppRoutes.home },
    { text: "Numbers", path: AppRoutes.numbers },
    { text: "Add entry", path: AppRoutes.addEntry },
  ];

  return (
    <>
      <Slide className="no-print" direction="down" in={!scrollTrigger}>
        <AppBar color="secondary" enableColorOnDark>
          <Toolbar>
            <IconButton size="large" edge="start" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
            <Box ml={1} flexGrow={1}>
              <Typography component="h1">Danish course notes</Typography>
            </Box>
            <IconButton onClick={handleToggleTheme}>
              {theme === darkTheme ? <LightModeIcon /> : <BedtimeIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>
      </Slide>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h3">🇩🇰</Typography>
            <List>
              {drawerOptions.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    component={Link}
                    to={item.path}
                    onClick={handleDrawerToggle}
                  >
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </>
  );
}