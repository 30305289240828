import { Word } from "../grammar/Word";
import { nounCommon, preposition, verb } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";
import { numberEleven, numberOne, numberTwentyOne } from "./Numbers";

export const dictionaryE: Word[] = [
  nounCommon(
    { dk: "edderkop", en: "spider" },
    { dk: "edderkoppen", en: "spider" },
    {
      dk: "edderkopper",
      en: "spiders",
    },
    { dk: "edderkopperne", en: "spiders" },
    1,
    [Keyword.ANIMALS]
  ),
  preposition({ dk: "efter", en: "after" }, 2, [Keyword.TIME]),
  nounCommon(
    { dk: "elefant", en: "elephant" },
    { dk: "elefanten", en: "elephant" },
    {
      dk: "elefanter",
      en: "elephants",
    },
    { dk: "elefanterne", en: "elephants" },
    1,
    [Keyword.ANIMALS]
  ),
  numberEleven,
  verb(
    { dk: "elske", en: "love" },
    { dk: "elsker", en: "love" },
    { dk: "elskede", en: "loved" },
    2,
    [Keyword.CONVERSATIONS, Keyword.RELATIONSHIPS]
  ),
  numberOne,
  numberTwentyOne,
  verb(
    { dk: "være", en: "be" },
    { dk: "er", en: "be" },
    { dk: "var", en: "was/were" },
    2,
    [Keyword.CONVERSATIONS]
  ),
];