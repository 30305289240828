import { Word } from "../grammar/Word";
import {
  adjective,
  adverb,
  nounCommon,
  nounNeuterPlural,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";
import {
  numberSixty,
  numberTen,
  numberThirteen,
  numberThirty,
  numberThousand,
  numberThree,
  numberTwelve,
  numberTwenty,
  numberTwentyThree,
  numberTwentyTwo,
  numberTwo,
} from "./Numbers";

export const dictionaryT: Word[] = [
  nounCommon(
    { dk: "tante", en: "aunt" },
    { dk: "tanten", en: "aunt" },
    {
      dk: "tanter",
      en: "aunts",
    },
    { dk: "tanterne", en: "aunts" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  numberTen,
  nounCommon(
    { dk: "tid", en: "time" },
    { dk: "tiden", en: "time" },
    {
      dk: "tider",
      en: "times",
    },
    { dk: "tiderne", en: "times" },
    2,
    [Keyword.TIME]
  ),
  adverb({ dk: "tilbage", en: "back" }, 2, [Keyword.CONVERSATIONS]),
  verb(
    { dk: "tillade", en: "allow" },
    { dk: "tillader", en: "allow" },
    { dk: "tillod", en: "allowed" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  nounCommon(
    { dk: "time", en: "hour" },
    { dk: "timen", en: "hour" },
    {
      dk: "timer",
      en: "hours",
    },
    { dk: "timerne", en: "hours" },
    2,
    [Keyword.TIME, Keyword.WORK]
  ),
  nounCommon(
    { dk: "tirsdag", en: "Tuesday" },
    { dk: "tirsdagen", en: "Tuesday" },
    {
      dk: "tirsdage",
      en: "Tuesdays",
    },
    { dk: "tirsdagene", en: "Tuesdays" },
    2,
    [Keyword.TIME]
  ),
  numberTwo,
  numberTwelve,
  numberTwentyTwo,
  nounCommon(
    { dk: "torsdag", en: "Thursday" },
    { dk: "torsdagen", en: "Thursday" },
    {
      dk: "torsdage",
      en: "Thursdays",
    },
    { dk: "torsdagene", en: "Thursdays" },
    2,
    [Keyword.TIME]
  ),
  numberThree,
  numberThirty,
  numberTwentyThree,
  numberSixty,
  numberThirteen,
  nounCommon(
    { dk: "trusse", en: "briefs / knickers" },
    { dk: "trussen", en: "briefs / knickers" },
    {
      dk: "trusser",
      en: "briefs / knickers",
    },
    { dk: "trusserne", en: "briefs / knickers" },
    1, // TODO unit
    [Keyword.CLOTHES]
  ),
  numberThousand,
  numberTwenty,
  adjective(
    { dk: "træt", en: "tired" },
    { dk: "træt", en: "tired" },
    {
      dk: "trætte",
      en: "tired",
    },
    {
      dk: "trættere",
      en: "more tired",
    },
    { dk: "trættest", en: "most tired" },
    2,
    [Keyword.LEISURE, Keyword.WORK]
  ),
  nounNeuterPlural(
    { dk: "tøj", en: "clothes" },
    { dk: "tøjet", en: "clothes" },
    1,
    [Keyword.CLOTHES]
  ),
];