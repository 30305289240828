import { createContext, PropsWithChildren, useContext, useState } from "react";
import { darkTheme, lightTheme } from "./Theme";
import { Theme, ThemeProvider } from "@mui/material";

interface ThemeState {
  readonly theme: Theme;
  readonly toggleTheme: any;
}

const ThemeContext = createContext<ThemeState | undefined>(undefined);

export function useThemeContext() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error(
      "useThemeContext must be used within an ThemeContext.Provider"
    );
  }

  return context;
}

interface Props extends PropsWithChildren {}

export function ThemeContextProvider(props: Props) {
  const { children } = props;
  const [theme, setTheme] = useState(darkTheme);

  const toggleTheme = () => {
    if (theme === darkTheme) {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}