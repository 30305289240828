import { Word } from "../grammar/Word";
import {
  numberFifty,
  numberHundred,
  numberNinety,
  numberSeventy,
} from "./Numbers";
import { adjective, adverb, nounCommon, verb } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryH: Word[] = [
  numberNinety,
  numberSeventy,
  numberFifty,
  nounCommon(
    { dk: "have", en: "garden" },
    { dk: "haven", en: "garden" },
    {
      dk: "haver",
      en: "gardens",
    },
    { dk: "haverne", en: "gardens" },
    1,
    [Keyword.LEISURE]
  ),
  verb(
    { dk: "have ... på", en: "wear" },
    { dk: "har ... på", en: "wear" },
    {
      dk: "havde ... på",
      en: "worn",
    },
    2,
    [Keyword.CLOTHES]
  ),
  nounCommon(
    { dk: "hest", en: "horse" },
    { dk: "hesten", en: "horse" },
    {
      dk: "heste",
      en: "horses",
    },
    { dk: "hestene", en: "horses" },
    1,
    [Keyword.ANIMALS]
  ),
  nounCommon(
    { dk: "hund", en: "dog" },
    { dk: "hunden", en: "dog" },
    {
      dk: "hunde",
      en: "dogs",
    },
    { dk: "hundene", en: "dogs" },
    1,
    [Keyword.ANIMALS]
  ),
  numberHundred,
  adverb({ dk: "hvad", en: "what" }, 2, [Keyword.CONVERSATIONS]),
  adverb({ dk: "hvem", en: "who" }, 2, [Keyword.CONVERSATIONS, Keyword.PEOPLE]),
  adverb({ dk: "hvilke", en: "which / what (plural)" }, 2, [
    Keyword.CONVERSATIONS,
  ]),
  adverb({ dk: "hvilken", en: "which / what (common)" }, 2, [
    Keyword.CONVERSATIONS,
  ]),
  adverb({ dk: "hvilket", en: "which / what (neuter)" }, 2, [
    Keyword.CONVERSATIONS,
  ]),
  adjective(
    { dk: "hvid", en: "white" },
    { dk: "hvidt", en: "white" },
    {
      dk: "hvide",
      en: "white",
    },
    {
      dk: "hvidere",
      en: "whiter",
    },
    { dk: "hvidest / hvideste", en: "whitest" },
    2,
    [Keyword.COLORS]
  ),
  adverb({ dk: "hvis", en: "if" }, 2, [Keyword.CONVERSATIONS]),
  adverb({ dk: "hvor", en: "where" }, 2, [Keyword.CONVERSATIONS]),
  adverb({ dk: "hvordan", en: "how" }, 2, [Keyword.CONVERSATIONS]),
  adverb({ dk: "hvorfor", en: "why" }, 2, [Keyword.CONVERSATIONS]),
  adverb({ dk: "hvornår", en: "when" }, 2, [
    Keyword.CONVERSATIONS,
    Keyword.TIME,
  ]),
  verb(
    { dk: "hører", en: "hear" },
    { dk: "hører", en: "hear" },
    {
      dk: "hørte",
      en: "heard",
    },
    2,
    [Keyword.CONVERSATIONS]
  ),
];