import { KeywordData } from "../../course/Keyword";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

interface Props {
  readonly keywords: string[];
  readonly toggleKeyword: (id: string, checked: boolean) => void;
}

export default function KeywordCheckboxGroup(props: Props) {
  return (
    <FormGroup row>
      {Object.entries(KeywordData).map((entry) => (
        <FormControlLabel
          key={entry[0]}
          control={
            <Checkbox
              checked={props.keywords.indexOf(entry[0]) !== -1}
              onChange={(event) =>
                props.toggleKeyword(entry[0], event.target.checked)
              }
            />
          }
          label={entry[1].name}
        />
      ))}
    </FormGroup>
  );
}
