import { Word } from "../grammar/Word";
import { adjective, verb } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryG: Word[] = [
  adjective(
    { dk: "grøn", en: "green" },
    { dk: "grønt", en: "green" },
    {
      dk: "grønne",
      en: "green",
    },
    {
      dk: "grønnere",
      en: "greener",
    },
    { dk: "grønnest", en: "greenest" },
    2,
    [Keyword.COLORS]
  ),
  verb(
    { dk: "gå", en: "walk" },
    { dk: "går", en: "walk" },
    {
      dk: "gik",
      en: "went",
    },
    2,
    [Keyword.LEISURE, Keyword.WORK]
  ),
];