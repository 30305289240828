import { Word } from "../grammar/Word";
import { nounCommon, preposition, verb } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryU: Word[] = [
  preposition({ dk: "uden", en: "without" }, 2, [Keyword.FOOD, Keyword.PEOPLE]),
  nounCommon(
    { dk: "ugle", en: "owl" },
    { dk: "uglen", en: "owl" },
    {
      dk: "ugler",
      en: "owls",
    },
    { dk: "uglerne", en: "owls" },
    1,
    [Keyword.ANIMALS]
  ),
  preposition({ dk: "under", en: "below" }, 2, [Keyword.PLACES]),
  verb(
    { dk: "undervise", en: "teach/learn" },
    { dk: "underviser", en: "teach/learn" },
    {
      dk: "underviste",
      en: "taught/learnt",
    },
    2,
    [Keyword.EDUCATION]
  ),
];