import { Numeral } from "../grammar/Word";

/**
 * nul
 */
export const numberZero = new Numeral(2, [], { dk: "nul", en: "zero" });
/**
 * en
 */
export const numberOne = new Numeral(2, [], { dk: "en", en: "one" });
/**
 * to
 */
export const numberTwo = new Numeral(2, [], { dk: "to", en: "two" });
/**
 * tre
 */
export const numberThree = new Numeral(2, [], { dk: "tre", en: "three" });
/**
 * fire
 */
export const numberFour = new Numeral(2, [], { dk: "fire", en: "four" });
/**
 * fem
 */
export const numberFive = new Numeral(2, [], { dk: "fem", en: "five" });
/**
 * seks
 */
export const numberSix = new Numeral(2, [], { dk: "seks", en: "six" });
/**
 * syv
 */
export const numberSeven = new Numeral(2, [], { dk: "syv", en: "seven" });
/**
 * otte
 */
export const numberEight = new Numeral(2, [], { dk: "otte", en: "eight" });
/**
 * ni
 */
export const numberNine = new Numeral(2, [], { dk: "ni", en: "nine" });
/**
 * ti
 */
export const numberTen = new Numeral(2, [], { dk: "ti", en: "ten" });
/**
 * elleve
 */
export const numberEleven = new Numeral(2, [], { dk: "elleve", en: "eleven" });
/**
 * tolv
 */
export const numberTwelve = new Numeral(2, [], { dk: "tolv", en: "twelve" });
/**
 * tretten
 */
export const numberThirteen = new Numeral(2, [], {
  dk: "tretten",
  en: "thirteen",
});
/**
 * fjorten
 */
export const numberFourteen = new Numeral(2, [], {
  dk: "fjorten",
  en: "fourteen",
});
/**
 * femten
 */
export const numberFifteen = new Numeral(2, [], {
  dk: "femten",
  en: "fifteen",
});
/**
 * seksten
 */
export const numberSixteen = new Numeral(2, [], {
  dk: "seksten",
  en: "sixteen",
});
/**
 * sytten
 */
export const numberSeventeen = new Numeral(2, [], {
  dk: "sytten",
  en: "seventeen",
});
/**
 * atten
 */
export const numberEighteen = new Numeral(2, [], {
  dk: "atten",
  en: "eighteen",
});
/**
 * nitten
 */
export const numberNineteen = new Numeral(2, [], {
  dk: "nitten",
  en: "nineteen",
});
/**
 * tyve
 */
export const numberTwenty = new Numeral(2, [], { dk: "tyve", en: "twenty" });
/**
 * enogtyve
 */
export const numberTwentyOne = new Numeral(2, [], {
  dk: "enogtyve",
  en: "twenty one",
});
/**
 * toogtyve
 */
export const numberTwentyTwo = new Numeral(2, [], {
  dk: "toogtyve",
  en: "twenty two",
});
/**
 * treogtyve
 */
export const numberTwentyThree = new Numeral(2, [], {
  dk: "treogtyve",
  en: "twenty three",
});
/**
 * fireogtyve
 */
export const numberTwentyFour = new Numeral(2, [], {
  dk: "fireogtyve",
  en: "twenty four",
});
/**
 * femogtyve
 */
export const numberTwentyFive = new Numeral(2, [], {
  dk: "femogtyve",
  en: "twenty five",
});
/**
 * seksogtyve
 */
export const numberTwentySix = new Numeral(2, [], {
  dk: "seksogtyve",
  en: "twenty six",
});
/**
 * syvogtyve
 */
export const numberTwentySeven = new Numeral(2, [], {
  dk: "syvogtyve",
  en: "twenty seven",
});
/**
 * otteogtyve
 */
export const numberTwentyEight = new Numeral(2, [], {
  dk: "otteogtyve",
  en: "twenty eight",
});
/**
 * niogtyve
 */
export const numberTwentyNine = new Numeral(2, [], {
  dk: "niogtyve",
  en: "twenty nine",
});
/**
 * tredive
 */
export const numberThirty = new Numeral(2, [], { dk: "tredive", en: "thirty" });
/**
 * fyrre
 */
export const numberForty = new Numeral(2, [], { dk: "fyrre", en: "forty" });
/**
 * halvtreds
 */
export const numberFifty = new Numeral(2, [], { dk: "halvtreds", en: "fifty" });
/**
 * tres
 */
export const numberSixty = new Numeral(2, [], { dk: "tres", en: "sixty" });
/**
 * halvfjerds
 */
export const numberSeventy = new Numeral(2, [], {
  dk: "halvfjerds",
  en: "seventy",
});
/**
 * firs
 */
export const numberEighty = new Numeral(2, [], { dk: "firs", en: "eighty" });
/**
 * halvfems
 */
export const numberNinety = new Numeral(2, [], {
  dk: "halvfems",
  en: "ninety",
});
/**
 * hundrede
 */
export const numberHundred = new Numeral(2, [], {
  dk: "hundrede",
  en: "hundred",
});
/**
 * tusind
 */
export const numberThousand = new Numeral(2, [], {
  dk: "tusind",
  en: "thousand",
});
/**
 * million
 */
export const numberMillion = new Numeral(2, [], {
  dk: "million",
  en: "million",
});

export const numbers: Numeral[] = [
  numberZero,
  numberOne,
  numberTwo,
  numberThree,
  numberFour,
  numberFive,
  numberSix,
  numberSeven,
  numberEight,
  numberNine,
  numberTen,
  numberEleven,
  numberTwelve,
  numberThirteen,
  numberFourteen,
  numberFifteen,
  numberSixteen,
  numberSeventeen,
  numberEighteen,
  numberNineteen,
  numberTwenty,
  numberTwentyOne,
  numberTwentyTwo,
  numberTwentyThree,
  numberTwentyFour,
  numberTwentyFive,
  numberTwentySix,
  numberTwentySeven,
  numberTwentyEight,
  numberTwentyNine,
  numberThirty,
  numberForty,
  numberFifty,
  numberSixty,
  numberSeventy,
  numberEighty,
  numberNinety,
  numberHundred,
  numberThousand,
  numberMillion,
];
