import { ListItem } from "@mui/material";
import {
  Adjective,
  Adverb,
  Conjunction,
  Noun,
  Numeral,
  Preposition,
  Verb,
  Word,
} from "../../grammar/Word";
import { PartOfSpeech } from "../../grammar/PartOfSpeech";
import NounListItem from "../grammar/NounListItem";
import VerbListItem from "../grammar/VerbListItem";
import ConjunctionListItem from "../grammar/ConjunctionListItem";
import AdverbListItem from "../grammar/AdverbListItem";
import AdjectiveListItem from "../grammar/AdjectiveListItem";
import PrepositionListItem from "../grammar/PrepositionListItem";
import NumeralListItem from "../grammar/NumeralListItem";

interface Props {
  readonly word: Word;
}

export default function DictionaryEntryListItem(props: Props) {
  let element: JSX.Element;
  switch (props.word.part) {
    case PartOfSpeech.ADJECTIVE: {
      element = <AdjectiveListItem word={props.word as Adjective} />;
      break;
    }
    case PartOfSpeech.ADVERB: {
      element = <AdverbListItem word={props.word as Adverb} />;
      break;
    }
    case PartOfSpeech.CONJUNCTION: {
      element = <ConjunctionListItem word={props.word as Conjunction} />;
      break;
    }
    case PartOfSpeech.NOUN: {
      element = <NounListItem word={props.word as Noun} />;
      break;
    }
    case PartOfSpeech.NUMERAL: {
      element = <NumeralListItem word={props.word as Numeral} />;
      break;
    }
    case PartOfSpeech.PREPOSITION: {
      element = <PrepositionListItem word={props.word as Preposition} />;
      break;
    }
    case PartOfSpeech.VERB: {
      element = <VerbListItem word={props.word as Verb} />;
      break;
    }
  }

  return <ListItem divider>{element}</ListItem>;
}
