import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseUnit from "../../../course/CourseUnit";
import { toggleAndReturnCopy } from "../../../utils/CollectionUtils";

export function useDictionaryFilterController() {
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [wordQuery, setWordQuery] = useState("");
  const [unitFrom, setUnitFrom] = useState(`${CourseUnit.first}`);
  const [unitTo, setUnitTo] = useState(`${CourseUnit.last}`);
  const [keywords, setKeywords] = useState<string[]>([]);

  const navigate = useNavigate();

  const search = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const paramsData = {
      word: wordQuery,
      unitFrom: unitFrom,
      unitTo: unitTo,
      keywords: `${keywords}`,
    };
    const params = new URLSearchParams(paramsData);
    navigate(`?${params.toString()}`);
  };

  const toggleKeyword = (id: string, checked: boolean) => {
    const newKeywords = toggleAndReturnCopy(keywords, id, checked);
    if (newKeywords !== undefined) {
      setKeywords(() => newKeywords);
    }
  };

  return {
    filtersExpanded: filtersExpanded,
    setFiltersExpanded: setFiltersExpanded,
    search: search,
    setWordQuery: setWordQuery,
    unitFrom: unitFrom,
    setUnitFrom: setUnitFrom,
    unitTo: unitTo,
    setUnitTo: setUnitTo,
    keywords: keywords,
    toggleKeyword: toggleKeyword,
  };
}
