import { Word } from "../grammar/Word";
import {
  adjective,
  adjectiveWithoutComparativeAndSuperlative,
  nounCommon,
  nounCommonSingular,
  nounCommonSingularIndefinite,
  nounNeuter,
  preposition,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";
import { numberMillion } from "./Numbers";

export const dictionaryM: Word[] = [
  nounCommonSingular(
    { dk: "mad", en: "food" },
    { dk: "maden", en: "food" },
    1,
    [Keyword.FOOD]
  ),
  nounCommonSingularIndefinite({ dk: "maj", en: "May" }, 2, [Keyword.TIME]),
  nounCommon(
    { dk: "mand", en: "man" },
    { dk: "manden", en: "man" },
    {
      dk: "mænd",
      en: "men",
    },
    { dk: "mændene", en: "men" },
    1,
    [Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "mandag", en: "Monday" },
    { dk: "mandagen", en: "Monday" },
    {
      dk: "mandage",
      en: "Mondays",
    },
    { dk: "mandagene", en: "Mondays" },
    2,
    [Keyword.TIME]
  ),
  nounCommonSingularIndefinite({ dk: "marts", en: "March" }, 2, [Keyword.TIME]),
  preposition({ dk: "med", en: "with" }, 2, [Keyword.FOOD, Keyword.PEOPLE]),
  preposition({ dk: "mellem", en: "between" }, 2, [
    Keyword.CONVERSATIONS,
    Keyword.PEOPLE,
  ]),
  adjective(
    { dk: "menneskelig", en: "human" },
    { dk: "menneskeligt", en: "human" },
    { dk: "menneskelige", en: "human" },
    { dk: "mere menneskelig", en: "more human" },
    { dk: "mest menneskelig", en: "most human" },
    2,
    [Keyword.CONVERSATIONS, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "middag", en: "noon" },
    { dk: "middag", en: "noon" },
    { dk: "middage", en: "noons" },
    { dk: "middage", en: "noons" },
    2,
    [Keyword.TIME]
  ),
  numberMillion,
  nounNeuter(
    { dk: "minut", en: "minute" },
    { dk: "minuttet", en: "minute" },
    {
      dk: "minutter",
      en: "minutes",
    },
    { dk: "minutterne", en: "minutes" },
    2,
    [Keyword.TIME]
  ),
  nounCommon(
    { dk: "mor", en: "mother" },
    { dk: "moren", en: "mother" },
    {
      dk: "mødre",
      en: "mothers",
    },
    { dk: "mødrene", en: "mothers" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "morgen", en: "morning" },
    { dk: "morgenen / morgnen", en: "morning" },
    {
      dk: "morgener / morgner",
      en: "mornings",
    },
    { dk: "morgenerne / morgnerne", en: "mornings" },
    2,
    [Keyword.TIME, Keyword.WORK]
  ),
  nounCommon(
    { dk: "morgenmad", en: "breakfast" },
    { dk: "morgenmaden", en: "breakfast" },
    {
      dk: "morgenmad",
      en: "breakfasts",
    },
    { dk: "morgenmadene", en: "breakfasts" },
    1,
    [Keyword.FOOD]
  ),
  adjectiveWithoutComparativeAndSuperlative(
    { dk: "mulig", en: "possible" },
    { dk: "muligt", en: "possible" },
    { dk: "mulige", en: "possible" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  adjective(
    { dk: "mørk", en: "dark" },
    { dk: "mørkt", en: "dark" },
    {
      dk: "mørke",
      en: "dark",
    },
    {
      dk: "mørkere",
      en: "darker",
    },
    { dk: "mørkest / mørkeste", en: "darkest" },
    2,
    [Keyword.COLORS]
  ),
  nounNeuter(
    { dk: "måltid", en: "meal" },
    { dk: "måltidet", en: "meal" },
    {
      dk: "måltider",
      en: "meal",
    },
    { dk: "måltiderne", en: "meal" },
    1,
    [Keyword.FOOD]
  ),
  nounCommon(
    { dk: "måned", en: "month" },
    { dk: "måneden", en: "month" },
    {
      dk: "måneder",
      en: "months",
    },
    { dk: "månederne", en: "months" },
    2,
    [Keyword.TIME, Keyword.WORK]
  ),
];