import { Word } from "../grammar/Word";
import {
  adjective,
  nounCommon,
  nounCommonSingularIndefinite,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";
import {
  numberNine,
  numberNineteen,
  numberTwentyNine,
  numberZero,
} from "./Numbers";

export const dictionaryN: Word[] = [
  nounCommon(
    { dk: "nat", en: "night" },
    { dk: "natten", en: "night" },
    {
      dk: "natter",
      en: "nights",
    },
    { dk: "naterne", en: "nights" },
    2,
    [Keyword.LEISURE, Keyword.TIME]
  ),
  nounCommon(
    { dk: "nederdel", en: "skirt" },
    { dk: "nederdelen", en: "skirt" },
    {
      dk: "nederdele",
      en: "skirts",
    },
    { dk: "nederdelerne", en: "skirts" },
    1,
    [Keyword.CLOTHES]
  ),
  numberNine,
  numberTwentyNine,
  numberNineteen,
  nounCommonSingularIndefinite({ dk: "november", en: "November" }, 2, [
    Keyword.TIME,
  ]),
  numberZero,
  adjective(
    { dk: "nylig", en: "recent" },
    { dk: "nyligt", en: "recent" },
    { dk: "nylige", en: "recent" },
    { dk: "mere nylig", en: "more recent" },
    { dk: "mest nylig", en: "most recent" },
    2,
    [Keyword.CONVERSATIONS]
  ),
];