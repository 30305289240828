import { Word } from "../grammar/Word";
import { nounCommon, preposition, verb } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryP: Word[] = [
  nounCommon(
    { dk: "pige", en: "girl" },
    { dk: "pigen", en: "girl" },
    {
      dk: "piger",
      en: "girls",
    },
    { dk: "pigerne", en: "girls" },
    1,
    [Keyword.PEOPLE]
  ),
  verb(
    { dk: "prøve", en: "try" },
    { dk: "prøver", en: "try" },
    { dk: "prøvede", en: "tried" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  preposition({ dk: "på", en: "at / upon / on" }, 2, [
    Keyword.CLOTHES,
    Keyword.PEOPLE,
    Keyword.PLACES,
  ]),
];