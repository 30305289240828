import { Grid } from "@mui/material";
import { Numeral } from "../../grammar/Word";
import {
  DanishWordTypography,
  TranslatedWordTypography,
} from "./WordTypography";

interface Props {
  readonly word: Numeral;
}

export default function NumeralListItem(props: Props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <DanishWordTypography word={props.word.text.dk} />
      </Grid>
      <Grid item xs={12}>
        <TranslatedWordTypography word={props.word.text.en} />
      </Grid>
    </Grid>
  );
}
