import { Word } from "../grammar/Word";
import {
  adjective,
  nounCommon,
  nounNeuterSingular,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryV: Word[] = [
  nounNeuterSingular(
    { dk: "vand", en: "water" },
    { dk: "vandet", en: "water" },
    1,
    [Keyword.FOOD]
  ),
  verb(
    { dk: "vide", en: "know" },
    { dk: "ved", en: "know" },
    {
      dk: "vidste",
      en: "knew",
    },
    2,
    [Keyword.CONVERSATIONS]
  ),
  adjective(
    { dk: "venstre", en: "left" },
    { dk: "venstre", en: "left" },
    { dk: "venstre", en: "left" },
    { dk: "mere venstre", en: "more left" },
    { dk: "mest venstre", en: "most left" },
    2,
    [Keyword.PLACES]
  ),
  adjective(
    { dk: "vigtig", en: "important" },
    { dk: "vigtigt", en: "important" },
    { dk: "vigtige", en: "important" },
    { dk: "vigtigere", en: "more important" },
    {
      dk: "vigtigst / vigtigest / vigtigste / vigtigeste",
      en: "most important",
    },
    2,
    [Keyword.CONVERSATIONS, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "vin", en: "wine" },
    { dk: "vinen", en: "wine" },
    {
      dk: "vine",
      en: "wines",
    },
    { dk: "vinene", en: "wines" },
    1,
    [Keyword.FOOD]
  ),
];