import { Translation } from "../locale/Translation";
import { Keyword } from "../course/Keyword";
import {
  Adjective,
  Adverb,
  Conjunction,
  Noun,
  Preposition,
  Verb,
} from "./Word";
import { Gender } from "./Gender";

export const adjective = (
  commonIndefinite: Translation,
  neuterIndefinite: Translation,
  pluralAndDefinite: Translation,
  comparative: Translation,
  superlative: Translation,
  unit: number,
  keywords: Keyword[]
) =>
  new Adjective(
    unit,
    keywords,
    commonIndefinite,
    neuterIndefinite,
    pluralAndDefinite,
    comparative,
    superlative
  );
export const adjectiveWithoutComparativeAndSuperlative = (
  commonIndefinite: Translation,
  neuterIndefinite: Translation,
  pluralAndDefinite: Translation,
  unit: number,
  keywords: Keyword[]
) =>
  new Adjective(
    unit,
    keywords,
    commonIndefinite,
    neuterIndefinite,
    pluralAndDefinite
  );

export const adverb = (text: Translation, unit: number, keywords: Keyword[]) =>
  new Adverb(unit, keywords, text);

export const conjunction = (
  text: Translation,
  unit: number,
  keywords: Keyword[]
) => new Conjunction(unit, keywords, text);

export const nounCommon = (
  singularIndefinite: Translation,
  singularDefinite: Translation,
  pluralIndefinite: Translation,
  pluralDefinite: Translation,
  unit: number,
  keywords: Keyword[]
) =>
  new Noun(
    unit,
    keywords,
    Gender.COMMON,
    singularIndefinite,
    singularDefinite,
    pluralIndefinite,
    pluralDefinite
  );
export const nounCommonSingular = (
  singularIndefinite: Translation,
  singularDefinite: Translation,
  unit: number,
  keywords: Keyword[]
) =>
  new Noun(unit, keywords, Gender.COMMON, singularIndefinite, singularDefinite);
export const nounCommonSingularIndefinite = (
  singularIndefinite: Translation,
  unit: number,
  keywords: Keyword[]
) => new Noun(unit, keywords, Gender.COMMON, singularIndefinite);
export const nounNeuter = (
  singularIndefinite: Translation,
  singularDefinite: Translation,
  pluralIndefinite: Translation,
  pluralDefinite: Translation,
  unit: number,
  keywords: Keyword[]
) =>
  new Noun(
    unit,
    keywords,
    Gender.NEUTER,
    singularIndefinite,
    singularDefinite,
    pluralIndefinite,
    pluralDefinite
  );
export const nounNeuterSingular = (
  singularIndefinite: Translation,
  singularDefinite: Translation,
  unit: number,
  keywords: Keyword[]
) =>
  new Noun(unit, keywords, Gender.NEUTER, singularIndefinite, singularDefinite);
export const nounNeuterPlural = (
  pluralIndefinite: Translation,
  pluralDefinite: Translation,
  unit: number,
  keywords: Keyword[]
) =>
  new Noun(
    unit,
    keywords,
    Gender.NEUTER,
    undefined,
    undefined,
    pluralIndefinite,
    pluralDefinite
  );

export const preposition = (
  text: Translation,
  unit: number,
  keywords: Keyword[]
) => new Preposition(unit, keywords, text);

export const verb = (
  infinitive: Translation,
  present: Translation,
  past: Translation,
  unit: number,
  keywords: Keyword[]
) => new Verb(unit, keywords, infinitive, present, past);