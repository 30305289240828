import { Word } from "../grammar/Word";
import {
  adjectiveWithoutComparativeAndSuperlative,
  nounCommon,
  nounCommonSingular,
  nounCommonSingularIndefinite,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";
import { numberEighteen } from "./Numbers";

export const dictionaryA: Word[] = [
  nounCommon(
    { dk: "aften", en: "evening" },
    { dk: "aftenen / aftnen", en: "evening" },
    {
      dk: "aftener / aftner",
      en: "evenings",
    },
    { dk: "aftenerne / aftnerne", en: "evenings" },
    2,
    [Keyword.TIME, Keyword.WORK]
  ),
  nounCommonSingular(
    { dk: "aftensmad", en: "dinner" },
    { dk: "aftensmaden", en: "dinner" },
    1,
    [Keyword.FOOD]
  ),
  nounCommon(
    { dk: "and", en: "duck" },
    { dk: "anden", en: "duck" },
    {
      dk: "ænder",
      en: "ducks",
    },
    { dk: "ænderne", en: "ducks" },
    1,
    [Keyword.ANIMALS, Keyword.FOOD]
  ),
  adjectiveWithoutComparativeAndSuperlative(
    { dk: "anderledes", en: "different" },
    { dk: "anderledes", en: "different" },
    { dk: "anderledes", en: "different" },
    2,
    [Keyword.CONVERSATIONS, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "appelsin", en: "orange" },
    { dk: "appelsinen", en: "orange" },
    {
      dk: "appelsiner",
      en: "oranges",
    },
    { dk: "appelsinerne", en: "oranges" },
    1,
    [Keyword.FOOD]
  ),
  nounCommonSingularIndefinite({ dk: "april", en: "April" }, 2, [Keyword.TIME]),
  verb(
    { dk: "arbejde", en: "work" },
    { dk: "arbejder", en: "work" },
    {
      dk: "arbejdede",
      en: "worked",
    },
    2,
    [Keyword.WORK]
  ),
  numberEighteen,
  nounCommonSingularIndefinite({ dk: "august", en: "August" }, 2, [
    Keyword.TIME,
  ]),
];