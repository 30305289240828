export const enum Keyword {
  ANIMALS,
  CLOTHES,
  COLORS,
  CONVERSATIONS,
  EDUCATION,
  FAMILY,
  FOOD,
  LEISURE,
  PEOPLE,
  PLACES,
  RELATIONSHIPS,
  TIME,
  WORK,
}

type KeywordDataType = { [id: number]: { readonly name: string } };

export const KeywordData: KeywordDataType = {
  [Keyword.ANIMALS]: { name: "Animals" },
  [Keyword.CLOTHES]: { name: "Clothes" },
  [Keyword.COLORS]: { name: "Colors" },
  [Keyword.CONVERSATIONS]: { name: "Conversations" },
  [Keyword.EDUCATION]: { name: "Education" },
  [Keyword.FAMILY]: { name: "Family" },
  [Keyword.FOOD]: { name: "Food" },
  [Keyword.LEISURE]: { name: "Leisure" },
  [Keyword.PEOPLE]: { name: "People" },
  [Keyword.PLACES]: { name: "Places" },
  [Keyword.RELATIONSHIPS]: { name: "Relationships" },
  [Keyword.TIME]: { name: "Time" },
  [Keyword.WORK]: { name: "Work" },
};
