import { Grid, Typography } from "@mui/material";
import { Verb } from "../../grammar/Word";
import {
  DanishWordTypography,
  TranslatedWordTypography,
} from "./WordTypography";

interface Props {
  readonly word: Verb;
}

export default function VerbListItem(props: Props) {
  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography variant="body2" component="span">
          at
        </Typography>{" "}
        <DanishWordTypography word={props.word.infinitive.dk} />
      </Grid>
      <Grid item xs={3}>
        <DanishWordTypography word={props.word.present.dk} />
      </Grid>
      <Grid item xs={6}>
        <DanishWordTypography word={props.word.past.dk} />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" component="span">
          to
        </Typography>{" "}
        <TranslatedWordTypography word={props.word.infinitive.en} />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography word={props.word.present.en} />
      </Grid>
      <Grid item xs={6}>
        <TranslatedWordTypography word={props.word.past.en} />
      </Grid>
    </Grid>
  );
}
