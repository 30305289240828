import { PartOfSpeech } from "./PartOfSpeech";
import { Translation } from "../locale/Translation";
import { Gender } from "./Gender";
import { Keyword } from "../course/Keyword";

export class Word {
  constructor(
    readonly part: PartOfSpeech,
    readonly unit: number,
    readonly keywords: Keyword[]
  ) {}
}

export class Adjective extends Word {
  constructor(
    unit: number,
    keywords: Keyword[],
    /**
     * Common singular indefinite
     */
    readonly commonIndefinite: Translation,
    /**
     * Neuter singular indefinite (t-form)
     */
    readonly neuterIndefinite?: Translation,
    /**
     * Plural and definite (e-form)
     */
    readonly pluralAndDefinite?: Translation,
    /**
     * Comparative (more)
     */
    readonly comparative?: Translation,
    /**
     * Superlative (most)
     */
    readonly superlative?: Translation
  ) {
    super(PartOfSpeech.ADJECTIVE, unit, keywords);
  }
}

export class Adverb extends Word {
  constructor(unit: number, keywords: Keyword[], readonly text: Translation) {
    super(PartOfSpeech.ADVERB, unit, keywords);
  }
}

export class Conjunction extends Word {
  constructor(unit: number, keywords: Keyword[], readonly text: Translation) {
    super(PartOfSpeech.CONJUNCTION, unit, keywords);
  }
}

export class Noun extends Word {
  constructor(
    unit: number,
    keywords: Keyword[],
    readonly gender: Gender,
    readonly singularIndefinite?: Translation,
    readonly singularDefinite?: Translation,
    readonly pluralIndefinite?: Translation,
    readonly pluralDefinite?: Translation
  ) {
    super(PartOfSpeech.NOUN, unit, keywords);
  }
}

export class Numeral extends Word {
  constructor(unit: number, keywords: Keyword[], readonly text: Translation) {
    super(PartOfSpeech.NUMERAL, unit, keywords);
  }
}

export class Preposition extends Word {
  constructor(unit: number, keywords: Keyword[], readonly text: Translation) {
    super(PartOfSpeech.PREPOSITION, unit, keywords);
  }
}

export class Verb extends Word {
  constructor(
    unit: number,
    keywords: Keyword[],
    readonly infinitive: Translation,
    readonly present: Translation,
    /**
     * Preterite (simple past tense)
     */
    readonly past: Translation
  ) {
    super(PartOfSpeech.VERB, unit, keywords);
  }
}
