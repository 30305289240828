import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import MainPage from "./ui/main/MainPage";
import { CssBaseline } from "@mui/material";
import { ThemeContextProvider } from "./theme/ThemeContext";

function AppBody() {
  return useRoutes([{ path: "/*", element: <MainPage /> }]);
}

export default function App() {
  return (
    <ThemeContextProvider>
      <CssBaseline />
      <BrowserRouter>
        <AppBody />
      </BrowserRouter>
    </ThemeContextProvider>
  );
}