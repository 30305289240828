import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface Props {
  readonly labelId: string;
  readonly label: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
}

export default function CourseUnitSelect(props: Props) {
  return (
    <FormControl fullWidth>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>
      <Select
        labelId={props.labelId}
        label={props.label}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </Select>
    </FormControl>
  );
}
