import { Button, Collapse, Grid, TextField } from "@mui/material";
import { useDictionaryFilterController } from "./DictionaryFilterController";
import CourseUnitSelect from "../../course/CourseUnitSelect";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import KeywordCheckboxGroup from "../../course/KeywordCheckboxGroup";

export default function DictionaryFilterPanel() {
  const controller = useDictionaryFilterController();
  const filtersExpanded = controller.filtersExpanded;

  return (
    <form onSubmit={controller.search}>
      <Grid container padding={2} columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Word"
            variant="outlined"
            fullWidth
            onChange={(event) =>
              controller.setWordQuery(event.target.value ?? "")
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={filtersExpanded} timeout="auto" unmountOnExit>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={6}>
                <CourseUnitSelect
                  labelId="unit-from-label"
                  label="Unit from"
                  value={controller.unitFrom}
                  onChange={(value) => controller.setUnitFrom(value)}
                />
              </Grid>
              <Grid item xs={6}>
                <CourseUnitSelect
                  labelId="unit-to-label"
                  label="Unit to"
                  value={controller.unitTo}
                  onChange={(value) => controller.setUnitTo(value)}
                />
              </Grid>
              <Grid item xs={12}>
                <KeywordCheckboxGroup
                  keywords={controller.keywords}
                  toggleKeyword={controller.toggleKeyword}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            endIcon={filtersExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            fullWidth
            onClick={() => controller.setFiltersExpanded(!filtersExpanded)}
          >
            {filtersExpanded ? "Hide filters" : "Show filters"}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            variant="outlined"
            endIcon={<SearchIcon />}
            fullWidth
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
