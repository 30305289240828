import { Word } from "../grammar/Word";
import { nounCommon, nounNeuter } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryOe: Word[] = [
  nounNeuter(
    { dk: "øjeblik", en: "moment" },
    { dk: "øjeblikket", en: "moment" },
    {
      dk: "øjeblikke",
      en: "moments",
    },
    { dk: "øjeblikkene", en: "moments" },
    2,
    [Keyword.CONVERSATIONS, Keyword.TIME, Keyword.WORK]
  ),
  nounCommon(
    { dk: "øl", en: "beer" },
    { dk: "øllen", en: "beer" },
    {
      dk: "øl",
      en: "beers",
    },
    { dk: "øllene", en: "beers" },
    1,
    [Keyword.FOOD]
  ),
  nounCommon(
    { dk: "øvelse", en: "practice" },
    { dk: "øvelsen", en: "practice" },
    {
      dk: "øvelser",
      en: "practices",
    },
    { dk: "øvelserne", en: "practices" },
    2,
    [Keyword.EDUCATION, Keyword.WORK]
  ),
];
