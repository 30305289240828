import { Word } from "../grammar/Word";
import {
  adjective,
  nounCommon,
  nounNeuter,
  nounNeuterPlural,
  preposition,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryB: Word[] = [
  preposition({ dk: "bag", en: "behind" }, 2, [Keyword.PLACES]),
  nounNeuter(
    { dk: "barn", en: "child" },
    { dk: "barnet", en: "child" },
    {
      dk: "børn",
      en: "children",
    },
    { dk: "børnene", en: "children" },
    1,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "bedstefar", en: "grandfather" },
    { dk: "bedstefaren", en: "grandfather" },
    {
      dk: "bedstefædre",
      en: "grandfathers",
    },
    { dk: "bedstefædrene", en: "grandfathers" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "bedstemor", en: "grandmother" },
    { dk: "bedstemoren / bedstemoderen", en: "grandmother" },
    {
      dk: "bedstemødre",
      en: "grandmothers",
    },
    { dk: "bedstemødrene", en: "grandmothers" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  adjective(
    { dk: "beskidt", en: "dirty" },
    { dk: "beskidt", en: "dirty" },
    { dk: "beskidte", en: "dirty" },
    { dk: "mere beskidt", en: "more dirty" },
    { dk: "mest beskidt", en: "most dirty" },
    2,
    [Keyword.CONVERSATIONS]
  ),
  nounCommon(
    { dk: "bjørn", en: "bear" },
    { dk: "bjørnen", en: "bear" },
    {
      dk: "bjørne",
      en: "bears",
    },
    { dk: "bjørnene", en: "bears" },
    1,
    [Keyword.ANIMALS]
  ),
  adjective(
    { dk: "blå", en: "blue" },
    { dk: "blåt", en: "blue" },
    {
      dk: "blå",
      en: "blue",
    },
    {
      dk: "mere blå",
      en: "more blue",
    },
    { dk: "mest blå", en: "most blue" },
    2,
    [Keyword.COLORS]
  ),
  nounCommon(
    { dk: "bog", en: "book" },
    { dk: "bogen", en: "book" },
    {
      dk: "bøger",
      en: "books",
    },
    { dk: "bøgerne", en: "books" },
    1,
    [Keyword.LEISURE, Keyword.WORK]
  ),
  nounCommon(
    { dk: "bror", en: "brother" },
    { dk: "broren", en: "brother" },
    {
      dk: "brødre",
      en: "brothers",
    },
    { dk: "brødrene", en: "brothers" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  verb(
    { dk: "bruge", en: "use" },
    { dk: "bruger", en: "use" },
    {
      dk: "brugte",
      en: "used",
    },
    2,
    [Keyword.CONVERSATIONS, Keyword.WORK]
  ),
  adjective(
    { dk: "brun", en: "brown" },
    { dk: "brunt", en: "brown" },
    {
      dk: "brune",
      en: "brown",
    },
    {
      dk: "brunere",
      en: "browner",
    },
    { dk: "brunest / bruneste", en: "brownest" },
    2,
    [Keyword.COLORS]
  ),
  nounNeuter(
    { dk: "brød", en: "bread" },
    { dk: "brødet", en: "bread" },
    {
      dk: "brød",
      en: "breads",
    },
    { dk: "brødene", en: "breads" },
    1,
    [Keyword.FOOD]
  ),
  nounNeuterPlural(
    { dk: "bukser", en: "pants / trousers" },
    { dk: "bukserne", en: "pants / trousers" },
    1,
    [Keyword.CLOTHES]
  ),
];