import { createTheme } from "@mui/material";
import { grey, red } from "@mui/material/colors";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: { default: grey[900] },
    primary: {
      main: red[300],
    },
    secondary: {
      main: red[700],
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: red[300],
    },
    secondary: {
      main: red[700],
    },
  },
});
