import { Typography } from "@mui/material";

interface WordProps {
  readonly word: string;
}

export function DanishWordTypography(props: WordProps) {
  return (
    <Typography variant="body1" component="span">
      {props.word}
    </Typography>
  );
}

export function TranslatedWordTypography(props: WordProps) {
  return (
    <Typography variant="body2" component="span">
      {props.word}
    </Typography>
  );
}
