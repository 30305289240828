import { useAddEntryController } from "./AddEntryController";
import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import CourseUnitSelect from "../course/CourseUnitSelect";
import KeywordCheckboxGroup from "../course/KeywordCheckboxGroup";

export default function AddEntryPage() {
  const controller = useAddEntryController();

  return (
    <>
      <form onSubmit={controller.add}>
        <Grid container padding={2} columnSpacing={2} rowSpacing={2}>
          <Grid item xs={8}>
            <TextField
              label="Word"
              variant="outlined"
              error={controller.wordErrorMessage !== ""}
              helperText={controller.wordErrorMessage}
              fullWidth
              value={controller.word}
              onChange={(event) => controller.setWord(event.target.value ?? "")}
            />
          </Grid>
          <Grid item xs={4}>
            <CourseUnitSelect
              labelId="unit-label"
              label="Unit"
              value={controller.unit}
              onChange={(value) => controller.setUnit(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <KeywordCheckboxGroup
              keywords={controller.keywords}
              toggleKeyword={controller.toggleKeyword}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="outlined" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={controller.snackbarOpen}
        autoHideDuration={3000}
        onClose={controller.closeSnackbar}
      >
        <Alert onClose={controller.closeSnackbar} severity="success">
          Thank you for your contribution!
        </Alert>
      </Snackbar>
    </>
  );
}
