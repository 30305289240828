import { Word } from "../grammar/Word";
import { nounNeuter } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryAa: Word[] = [
  nounNeuter(
    { dk: "år", en: "year" },
    { dk: "året", en: "year" },
    {
      dk: "år",
      en: "years",
    },
    { dk: "årene", en: "years" },
    2,
    [Keyword.TIME]
  ),
  nounNeuter(
    { dk: "århundrede", en: "century" },
    { dk: "århundredet", en: "century" },
    {
      dk: "århundreder",
      en: "centuries",
    },
    { dk: "århundrederne", en: "centuries" },
    2,
    [Keyword.TIME]
  ),
  nounNeuter(
    { dk: "årti", en: "decade" },
    { dk: "årtiet", en: "decade" },
    {
      dk: "årtier",
      en: "decades",
    },
    { dk: "årtierne", en: "decades" },
    2,
    [Keyword.TIME]
  ),
];