import { Word } from "../grammar/Word";
import {
  conjunction,
  nounCommon,
  nounCommonSingularIndefinite,
  preposition,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";
import { numberEight, numberTwentyEight } from "./Numbers";

export const dictionaryO: Word[] = [
  conjunction({ dk: "og", en: "and" }, 1, []),
  nounCommonSingularIndefinite({ dk: "oktober", en: "October" }, 2, [
    Keyword.TIME,
  ]),
  preposition({ dk: "omkring", en: "around" }, 2, [Keyword.PLACES]),
  nounCommon(
    { dk: "onkel", en: "uncle" },
    { dk: "onklen", en: "uncle" },
    {
      dk: "onkler",
      en: "uncles",
    },
    { dk: "onklerne", en: "uncles" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "onsdag", en: "Wednesday" },
    { dk: "onsdagen", en: "Wednesday" },
    {
      dk: "onsdage",
      en: "Wednesdays",
    },
    { dk: "onsdagene", en: "Wednesdays" },
    2,
    [Keyword.TIME]
  ),
  numberEight,
  numberTwentyEight,
  preposition({ dk: "over", en: "above" }, 2, [Keyword.PLACES]),
];