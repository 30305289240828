import { Word } from "../grammar/Word";
import {
  nounCommon,
  nounCommonSingularIndefinite,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryD: Word[] = [
  nounCommon(
    { dk: "dag", en: "day" },
    { dk: "dagen", en: "day" },
    {
      dk: "dage",
      en: "days",
    },
    { dk: "dagene", en: "days" },
    2,
    [Keyword.TIME, Keyword.WORK]
  ),
  nounCommonSingularIndefinite({ dk: "december", en: "December" }, 2, [
    Keyword.TIME,
  ]),
  nounCommon(
    { dk: "dreng", en: "boy" },
    { dk: "drengen", en: "boy" },
    {
      dk: "drenge",
      en: "boys",
    },
    { dk: "drengene", en: "boys" },
    1,
    [Keyword.PEOPLE]
  ),
  verb(
    { dk: "drikke", en: "drink" },
    { dk: "drikker", en: "drink" },
    { dk: "drak", en: "drank" },
    1,
    [Keyword.FOOD]
  ),
];