import { Word } from "../grammar/Word";
import { nounNeuter } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryAe: Word[] = [
  nounNeuter(
    { dk: "æble", en: "apple" },
    { dk: "æblet", en: "apple" },
    {
      dk: "æbler",
      en: "apples",
    },
    { dk: "æblerne", en: "apples" },
    1,
    [Keyword.FOOD]
  ),
  nounNeuter(
    { dk: "æg", en: "egg" },
    { dk: "ægget", en: "egg" },
    {
      dk: "æg",
      en: "eggs",
    },
    { dk: "æggene", en: "eggs" },
    1,
    [Keyword.FOOD]
  ),
];