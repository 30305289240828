import { Word } from "../grammar/Word";
import { dictionaryA } from "./Letter0A";
import { dictionaryB } from "./Letter0B";
import { dictionaryD } from "./Letter0D";
import { dictionaryE } from "./Letter0E";
import { dictionaryF } from "./Letter0F";
import { dictionaryG } from "./Letter0G";
import { dictionaryH } from "./Letter0H";
import { dictionaryI } from "./Letter0I";
import { dictionaryJ } from "./Letter0J";
import { dictionaryK } from "./Letter0K";
import { dictionaryL } from "./Letter0L";
import { dictionaryM } from "./Letter0M";
import { dictionaryN } from "./Letter0N";
import { dictionaryO } from "./Letter0O";
import { dictionaryP } from "./Letter0P";
import { dictionaryR } from "./Letter0R";
import { dictionaryS } from "./Letter0S";
import { dictionaryT } from "./Letter0T";
import { dictionaryU } from "./Letter0U";
import { dictionaryV } from "./Letter0V";
import { dictionaryAe } from "./Letter1Ae";
import { dictionaryOe } from "./Letter1Oe";
import { dictionaryAa } from "./Letter2Aa";

export const dictionary: Word[] = [
  // A
  ...dictionaryA,
  // B
  ...dictionaryB,
  // D
  ...dictionaryD,
  // E
  ...dictionaryE,
  // F
  ...dictionaryF,
  // G
  ...dictionaryG,
  // H
  ...dictionaryH,
  // I
  ...dictionaryI,
  // J
  ...dictionaryJ,
  // K
  ...dictionaryK,
  // L
  ...dictionaryL,
  // M
  ...dictionaryM,
  // N
  ...dictionaryN,
  // O
  ...dictionaryO,
  // P
  ...dictionaryP,
  // R
  ...dictionaryR,
  // S
  ...dictionaryS,
  // T
  ...dictionaryT,
  // U
  ...dictionaryU,
  // V
  ...dictionaryV,
  // Æ
  ...dictionaryAe,
  // Ø
  ...dictionaryOe,
  // Å
  ...dictionaryAa,
];
