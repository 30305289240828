import { Word } from "../grammar/Word";
import { nounCommon, verb } from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryK: Word[] = [
  verb(
    { dk: "kunne", en: "be able to" },
    { dk: "kan", en: "can" },
    {
      dk: "kunne",
      en: "could",
    },
    2,
    [Keyword.CONVERSATIONS]
  ),
  nounCommon(
    { dk: "kat", en: "cat" },
    { dk: "katten", en: "cat" },
    {
      dk: "katte",
      en: "cats",
    },
    { dk: "kattene", en: "cats" },
    1,
    [Keyword.ANIMALS]
  ),
  nounCommon(
    { dk: "kjole", en: "dress" },
    { dk: "kjolen", en: "dress" },
    {
      dk: "kjoler",
      en: "dresses",
    },
    { dk: "kjolerne", en: "dresses" },
    1,
    [Keyword.CLOTHES]
  ),
  nounCommon(
    { dk: "kvinde", en: "woman" },
    { dk: "kvinden", en: "woman" },
    {
      dk: "kvinder",
      en: "women",
    },
    { dk: "kvinderne", en: "women" },
    1,
    [Keyword.PEOPLE]
  ),
];