import DictionaryFilterPanel from "./filter/DictionaryFilterPanel";
import DictionaryEntryList from "./DictionaryEntryList";

export default function DictionaryPage() {
  return (
    <>
      <DictionaryFilterPanel />
      <DictionaryEntryList />
    </>
  );
}
