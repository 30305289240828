import { Word } from "../grammar/Word";
import {
  nounCommon,
  nounCommonSingularIndefinite,
  nounNeuter,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";

export const dictionaryJ: Word[] = [
  nounCommon(
    { dk: "jakke", en: "jacket" },
    { dk: "jakken", en: "jacket" },
    {
      dk: "jakker",
      en: "jackets",
    },
    { dk: "jakkerne", en: "jackets" },
    1,
    [Keyword.CLOTHES]
  ),
  nounNeuter(
    { dk: "jakkesæt", en: "suit" },
    { dk: "jakkesættet", en: "suit" },
    {
      dk: "jakkesæt",
      en: "suits",
    },
    { dk: "jakkesættet", en: "suits" },
    1,
    [Keyword.CLOTHES]
  ),
  nounCommonSingularIndefinite({ dk: "januar", en: "January" }, 2, [
    Keyword.TIME,
  ]),
  nounCommonSingularIndefinite({ dk: "juli", en: "July" }, 2, [Keyword.TIME]),
  nounCommonSingularIndefinite({ dk: "juni", en: "June" }, 2, [Keyword.TIME]),
];