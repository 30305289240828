import { Word } from "../grammar/Word";
import {
  adjective,
  adjectiveWithoutComparativeAndSuperlative,
  adverb,
  conjunction,
  nounCommon,
  nounCommonSingularIndefinite,
  preposition,
  verb,
} from "../grammar/WordCreators";
import { Keyword } from "../course/Keyword";
import {
  numberEighty,
  numberFifteen,
  numberFive,
  numberForty,
  numberFour,
  numberFourteen,
  numberTwentyFive,
  numberTwentyFour,
} from "./Numbers";

export const dictionaryF: Word[] = [
  verb(
    { dk: "falde", en: "fall" },
    { dk: "falder", en: "fall" },
    { dk: "faldt", en: "fell" },
    2,
    [Keyword.LEISURE, Keyword.WORK]
  ),
  nounCommon(
    { dk: "far", en: "father" },
    { dk: "faren", en: "father" },
    {
      dk: "fædre",
      en: "fathers",
    },
    { dk: "fædrene", en: "fathers" },
    2,
    [Keyword.FAMILY, Keyword.PEOPLE]
  ),
  nounCommon(
    { dk: "farve", en: "color" },
    { dk: "farven", en: "color" },
    {
      dk: "farver",
      en: "colors",
    },
    { dk: "farverne", en: "colors" },
    2,
    [Keyword.COLORS]
  ),
  adjective(
    { dk: "farverig", en: "colorful" },
    { dk: "farverigt", en: "colorful" },
    {
      dk: "farverige",
      en: "colorful",
    },
    {
      dk: "farverigere",
      en: "more colorful",
    },
    { dk: "farverigest / farverigeste", en: "most colorful" },
    2,
    [Keyword.COLORS]
  ),
  nounCommonSingularIndefinite({ dk: "februar", en: "February" }, 2, [
    Keyword.TIME,
  ]),
  numberFive,
  numberFifteen,
  numberTwentyFive,
  numberFour,
  numberTwentyFour,
  numberEighty,
  numberFourteen,
  conjunction({ dk: "for", en: "because" }, 2, [Keyword.CONVERSATIONS]),
  preposition({ dk: "for", en: "for" }, 2, []),
  adverb({ dk: "for nylig", en: "recently" }, 2, [Keyword.TIME]),
  verb(
    { dk: "fortælle", en: "tell" },
    { dk: "fortæller", en: "tell" },
    {
      dk: "fortalte",
      en: "told",
    },
    2,
    [Keyword.CONVERSATIONS]
  ),
  nounCommon(
    { dk: "fredag", en: "Friday" },
    { dk: "fredagen", en: "Friday" },
    {
      dk: "fredage",
      en: "Fridays",
    },
    { dk: "fredagene", en: "Fridays" },
    2,
    [Keyword.TIME]
  ),
  adjectiveWithoutComparativeAndSuperlative(
    { dk: "fremtidig", en: "future" },
    { dk: "fremtidigt", en: "future" },
    { dk: "fremtidige", en: "future" },
    2,
    [Keyword.TIME]
  ),
  nounCommon(
    { dk: "frokost", en: "lunch" },
    { dk: "frokosten", en: "lunch" },
    {
      dk: "frokoster",
      en: "lunches",
    },
    { dk: "frokosterne", en: "lunches" },
    1,
    [Keyword.FOOD]
  ),
  nounCommon(
    { dk: "fugl", en: "bird" },
    { dk: "fuglen", en: "bird" },
    {
      dk: "fugle",
      en: "birds",
    },
    { dk: "fuglene", en: "birds" },
    1,
    [Keyword.ANIMALS]
  ),
  numberForty,
  preposition({ dk: "før", en: "before" }, 2, [Keyword.TIME]),
];