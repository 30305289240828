import { Grid } from "@mui/material";
import { Adjective } from "../../grammar/Word";
import {
  DanishWordTypography,
  TranslatedWordTypography,
} from "./WordTypography";

interface Props {
  readonly word: Adjective;
}

export default function AdjectiveListItem(props: Props) {
  return (
    <Grid container>
      <Grid item xs={3}>
        <DanishWordTypography word={props.word.commonIndefinite.dk} />
      </Grid>
      <Grid item xs={3}>
        <DanishWordTypography word={props.word.neuterIndefinite?.dk ?? ""} />
      </Grid>
      <Grid item xs={6}>
        <DanishWordTypography word={props.word.pluralAndDefinite?.dk ?? ""} />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography word={props.word.commonIndefinite.en} />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography
          word={props.word.neuterIndefinite?.en ?? ""}
        />
      </Grid>
      <Grid item xs={6}>
        <TranslatedWordTypography
          word={props.word.pluralAndDefinite?.en ?? ""}
        />
      </Grid>
      <Grid item xs={3}>
        <DanishWordTypography word={props.word.comparative?.dk ?? ""} />
      </Grid>
      <Grid item xs={9}>
        <DanishWordTypography word={props.word.superlative?.dk ?? ""} />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography word={props.word.comparative?.en ?? ""} />
      </Grid>
      <Grid item xs={9}>
        <TranslatedWordTypography word={props.word.superlative?.en ?? ""} />
      </Grid>
    </Grid>
  );
}
