import { Grid, Typography } from "@mui/material";
import { Noun } from "../../grammar/Word";
import {
  DanishWordTypography,
  TranslatedWordTypography,
} from "./WordTypography";
import { Gender } from "../../grammar/Gender";

interface Props {
  readonly word: Noun;
}

export default function NounListItem(props: Props) {
  let article: string;
  if (props.word.singularIndefinite === undefined) {
    article = "";
  } else if (props.word.gender === Gender.COMMON) {
    article = "en";
  } else {
    article = "et";
  }

  const singularDefiniteEn =
    props.word.singularDefinite === undefined
      ? ""
      : `the ${props.word.singularDefinite.en}`;
  const pluralDefiniteEn =
    props.word.pluralDefinite === undefined
      ? ""
      : `the ${props.word.pluralDefinite.en}`;

  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography variant="body2" component="span">
          {article}
        </Typography>{" "}
        <DanishWordTypography word={props.word.singularIndefinite?.dk ?? ""} />
      </Grid>
      <Grid item xs={3}>
        <DanishWordTypography word={props.word.singularDefinite?.dk ?? ""} />
      </Grid>
      <Grid item xs={3}>
        <DanishWordTypography word={props.word.pluralIndefinite?.dk ?? ""} />
      </Grid>
      <Grid item xs={3}>
        <DanishWordTypography word={props.word.pluralDefinite?.dk ?? ""} />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography
          word={props.word.singularIndefinite?.en ?? ""}
        />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography word={singularDefiniteEn} />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography
          word={props.word.pluralIndefinite?.en ?? ""}
        />
      </Grid>
      <Grid item xs={3}>
        <TranslatedWordTypography word={pluralDefiniteEn} />
      </Grid>
    </Grid>
  );
}
